import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React from 'react'
import ContactUsFields from './contactusfields'
import './styles.css'
function Modale({ handleCloseModal, openModal }) {
    return (
        <>
            <Dialog
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
                fullWidth
               
            >
                <div className='contactUsModalWrapper'>
                    <DialogTitle id="alert-dialog-title">
                        <div className='ContactUsTitleContainer'>
                            <h2>Send Mail</h2>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <ContactUsFields />
                        </DialogContentText>
                    </DialogContent>
                </div>
            </Dialog>
        </>
    )
}

export default Modale