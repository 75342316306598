import { Box, Button, Grid } from '@mui/material';
import React from 'react';
import Cookies from 'universal-cookie';
import './styles.css';
import body from '../../assets/images/bodyImage.webp'

function Body() {
    const cookie = new Cookies();

    return (
        <Box className='bodyWrapper'>
          <Grid container>
            <Grid item xs={12} lg={6}>
                <Box className='bodyContainer'>
                    <h1>One Platform for all your crypto success.</h1>
                    <h2>The app for people on the go who want to seize every opportunity. Crypto made easy and fast.</h2>
                    <Button className='bodyButton'>
                        Start Now !
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
                <img src={body} width="100%" height="100%" />
            </Grid>
          </Grid>
        </Box>
    );
}

export default Body;
