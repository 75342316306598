import { Box, Grid } from '@mui/material';
import React from 'react';
import './styles.css';
import secure from '../../assets/images/secure.webp'
function CryptoCurrency() {

  return (
    <Box className="cryptoCurrencyWrapper">
      <Grid container>
        <Grid item xs={12} md={6}>
          <Box className='cryptocurrencyContent'>
            <h3>
              Over the past several years, the cryptocurrency industry has witnessed substantial growth,
              transitioning from a niche market to a significant financial sector. Despite this expansion, 
              the adoption rate of cryptocurrencies remains relatively low, suggesting that only a select few 
              are currently benefiting from the opportunities this new financial and technological frontier offers. 
              <br/>
              <br/>
              It's becoming more common to hear transformative stories from everyday individuals—cab drivers,
              bartenders, hairdressers—who have had their lives changed by engaging with cryptocurrencies. 
              <br/>
              <br/>
              However, for many, the challenge remains how to effectively and securely engage with digital 
              assets with ease and minimal effort. That's why we developed our app—to provide busy individuals 
              a boost in managing their crypto investments. Since our inception, we have been committed to
              demystifying the world of cryptocurrencies and have helped countless people gain significant
              value. As your first introduction to cryptocurrencies, we would be proud to guide you into 
              this realm of opportunities.
            </h3>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src={secure} alt='secure' width='90%' height='90%' />
        </Grid>
      </Grid>
    </Box>
  )
}

export default CryptoCurrency