import { Button, Checkbox, Container, FormControl, FormControlLabel, Grid, OutlinedInput, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './styles.css'
import Cookies from 'universal-cookie'
import { useFormik } from 'formik'
import { reduce, createTransaction, withdrawEmail, getUserById } from '../../api'
import { Box } from '@mui/system'
import withdraw from '../../assets/images/withdraw.svg'

function WithDraw() {
    const cookie = new Cookies()
    const [checked, setChecked] = useState(false);
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [amountValue, setAmountValue] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);

    const [user, setUser] = useState([])


    useEffect(
        () => {
            cookie.get('id') &&
                getUserById(cookie.get('id')).then(res => {
                    setUser(res.data)
                }).catch(error => console.log(error));
        },
        [])

    const handleAmountChange = (e) => {
        setAmountValue(e.target.value);
    };
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const formik = useFormik({
        initialValues: {
            wallet: '',
            receiver: '',
            amount: ''
        },
        onSubmit: () => {
            const amount = amountValue;
            const receiver = formik.values.receiver;

            const reload = () => {
                window.location.reload()
            }


            const data = {
                sender: user._id,
                receiver: formik.values.receiver,
                amount: amountValue,
            }

            if ((receiver === "" && amount === "") || amount <= 0 || receiver === "" || amount === "") {
                setErrorMessage("ERROR : Empty Fields or Negative Value!!");

            } else {
                if (amount <= user?.balance) {
                    if (!buttonClicked) {
                        setButtonClicked(true)
                        const func = createTransaction(data)
                        func.then((response) => {
                            if (response.success) {
                                setMessage("Your order has been processed, awaiting approval!! Kindly check transaction page.");
                                reduce(user.wallet, amount)
                                // withdrawEmail(user.email, amount);
                                setTimeout(reload, 3000);
                            } else {
                                alert("error")
                            }
                        })
                    }
                } else {
                    setErrorMessage("ERROR : Insufficent Balance!!");
                }
            }



        }
    })

    return (
        <div className={cookie.get('token') ? 'widthDrawWrapper' : 'widthDrawWrapper mobile'}>
            <Grid container>
                <Grid item xs={12} lg={6} sx={{ display: { xs: 'none', lg: 'block', }, display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img src={withdraw} alt='withdraw' />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div className='withDrawContainer'>
                        <p className='withdrawTitle'>Withdraw</p>
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                            {
                                message ?
                                    <div className='successMessageContainer'>
                                        <p>{message}</p>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                            {
                                errorMessage ?
                                    <div className='errorMessageContainer' id="message">
                                        <p>{errorMessage}</p>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                        <div className='formWrapper'>
                            <form onSubmit={formik.handleSubmit} className="formContainer" >
                                <Box className='myWalletContainer'>
                                    <p style={{ color: "var(--white)" }}>My wallet:</p>
                                    <FormControl sx={{ width: '100%' }}>
                                        <OutlinedInput
                                            fullWidth
                                            size="small"
                                            disabled
                                            id="outlined-basic"
                                            value={user.wallet}
                                            variant="outlined"
                                            className='withdrawInput'
                                        />
                                    </FormControl>
                                </Box>
                                <Box className='toWalletContainer'>
                                    <p style={{ color: "var(--white)" }}>To:</p>
                                    <FormControl sx={{ width: '100%' }}>
                                        <OutlinedInput
                                            fullWidth
                                            size="small"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.receiver}
                                            name="receiver"
                                            id="outlined-basic"
                                            placeholder="Enter Wallet"
                                            variant="outlined"
                                            className='withdrawInput'
                                        />
                                    </FormControl>
                                </Box>
                                <Box className='withdrawAmountContainer'>
                                    <p style={{ color: "var(--white)" }}>Amount:</p>
                                    <div style={{ display: 'flex' }}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <OutlinedInput
                                                error={amountValue > user?.balance}
                                                fullWidth
                                                size="small"
                                                // onBlur={updateInput}
                                                onChange={handleAmountChange}
                                                value={amountValue}
                                                // InputProps={{ inputProps: { min: 1, max: user.balance } }}
                                                name="amount"
                                                id="outlined-basic"
                                                type="number"
                                                placeholder="Enter Amount"
                                                variant="outlined"
                                                className='withdrawInput'
                                            />
                                        </FormControl>
                                        <Button onClick={() => setAmountValue(user.balance)} className='maxbutton'>
                                            MAX
                                        </Button>
                                    </div>
                                </Box>
                                <Box className='withdrawCheckboxContainer'>
                                    <FormControlLabel
                                        control={<Checkbox color='default' sx={{ color: "white" }} />}
                                        label="Withdraw Now"
                                        checked={checked}
                                        onChange={handleChange}
                                        sx={{ color: "white" }}
                                    />
                                </Box>
                                <Box className='withdrawSendContainer'>
                                    {
                                        checked ?
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                style={{ width: '100%' }}
                                                className="withdrawSubmitButton">Withdraw</Button>
                                            :
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                className="withdrawSubmitButton"
                                                disabled>Withdraw</Button>
                                    }

                                </Box>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>

        </div>
    )
}

export default WithDraw