import React from 'react'
import './styles.css'
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material'
import map from '../../assets/images/map.webp'
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

function Features() {
    const reviews = [
        {
            id: 1,
            name: "Alice Johnson",
            rating: 5,
            comment: "Fantastic service! Everything was straightforward and easy to use. The platform's interface is intuitive and user-friendly, which made my experience very pleasant. Highly recommend for anyone looking to get started with crypto investments!"
        },
        {
            id: 2,
            name: "Bob Smith",
            rating: 5,
            comment: "Very good overall, but could use more advanced features for seasoned traders. The basics are covered well, but adding more detailed analysis tools would make this the perfect trading platform."
        },
        {
            id: 3,
            name: "Carol Lee",
            rating: 5,
            comment: "Decent service, but I had some issues with customer support being slow to respond. It would be great to see improvements in the speed and helpfulness of the support team. The trading itself is straightforward."
        },
        {
            id: 4,
            name: "Dave Wilson",
            rating: 5,
            comment: "Needs significant improvement on the user interface and the overall user experience. I found it difficult to navigate through the platform, and some of the features did not work as expected."
        },
        {
            id: 5,
            name: "Eve Adams",
            rating: 5,
            comment: "I had a poor experience, not what I expected at all. The platform is buggy, and transactions are slower than advertised. Customer service was not helpful in resolving my issues, which made the situation frustrating."
        }
    ];
    
    

    const renderStars = (rating) => {
        let stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                i < rating ? <StarIcon key={i} color="warning" fontSize="large"/> : <StarBorderIcon key={i} color="warning" fontSize="large"/>
            );
        }
        return stars;
    };

    return (
        <Box className='featuresWrapper'>
            <h1>Join a worldwide network</h1>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <img src={map} width="100%" height="100%" />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Box className='featuresContent'>
                        {reviews.map(review => (
                            <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
                                <Box display="flex" alignItems="center" justifyContent="center">
                                    {renderStars(review?.rating)}
                                </Box>
                                <h3 style={{textAlign:"center"}}>“{review?.comment}”</h3>
                                <Typography variant="h5" component="h5" sx={{textAlign:"center"}}>—{review?.name}</Typography>
                            </Paper>
                        ))}
                    </Box>
                </Grid>
            </Grid>
            <Container className='featuresCard'>
                <h1>Thrive in the crypto market while balancing<br/> your other commitments.</h1>
                <Button className='featuresCardButton'>
                    Let's Go !
                </Button>
            </Container>
        </Box>
    )
}

export default Features