import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import './style.css'
import box from '../../assets/images/box.png'
import { Link } from 'react-router-dom'
import plane from '../../assets/images/plane.webp'
import graphs from '../../assets/images/graphs.webp'
import convert from '../../assets/images/convert.webp'
import rewards from '../../assets/images/rewards.webp'
function MobileApp() {

   const data = [
        {
            title: "Manage Your Crypto",
            description: "Access your cryptocurrency easily and maintain full control over your assets for ultimate financial freedom.",
            image:plane,
            width:"450",
            height:"230"
        },
        {
            title: "Build Your Digital Asset Collection",
            description: "Effortlessly acquire and sell a wide range of digital assets, personalizing your investment strategy.",
            image:graphs,
            width:"400",
            height:"200"
        },
        {
            title: "Flexible Asset Exchange",
            description: "Exchange any cryptocurrency at your convenience, whether between crypto types or into traditional currency.",
            image: convert,
            width:"250",
            height:"250"
        },
        {
            title: "Gain Crypto Rewards",
            description: "Keep your chosen cryptocurrencies to earn generous rewards as part of our savings program.",
            image:rewards,
            width:"250",
            height:"250"
        }
    ]
    

    return (
        <Container>
            <Box className='mobileAppWrapper'>
            <Box sx={{ flexGrow: 1, padding: 2 }}>
                <Grid container spacing={2}>
                    {data.map((service, index) => (
                        <Grid item xs={12} sm={6} md={6} key={index}>
                            <Paper elevation={0} sx={{height:390 , borderRadius:"24px" , backgroundColor:"var(--white)"}}>
                                <Box sx={{padding:2}}>
                                    <h1>{service?.title}</h1>
                                    <h3 style={{width:"60%" , marginBottom:"0px"}}>{service?.description}</h3>
                                </Box>
                                <Box sx={{display:"flex" , alignItems:"center" , justifyContent:"flex-end" }}>
                                    <img src={service?.image} alt={service?.title} width={service?.width} height={service?.height}/>
                                </Box>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            </Box>
        </Container>
    )
}

export default MobileApp