import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link } from 'react-router-dom'
import SignupFields from './signupFields'
import './styles.css'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { useState } from 'react'
import signup from '../../assets/images/signup.jpg'
import PersonAddIcon from '@mui/icons-material/PersonAdd';

function Signup() {
    const [errormessage, setErrorMessage] = useState('')
    return (
        <>
            <Grid container fluid >
                <Grid item xs={12} lg={4} className="SignupWrapper">
                    <img src={signup} width='100%' height='60%'/>
                </Grid>
                <Grid item xs={12} lg={8} className="SignupWrapper">
                    <Box className='SignupContainer'>
                        <div className='SignupContainerChildOne'>
                           <PersonAddIcon sx={{fontSize:'100px' , color:'var(--green)' , marginBottom:"16px"}} /> 
                            {
                                errormessage ?
                                    <p style={{ color: 'green' }}>{errormessage}</p>
                                    :
                                    null
                            }
                            <SignupFields setErrorMessage={setErrorMessage} />
                            <p className='SideSigninSignup' style={{color:'var(--black)'}}>Already have an account.
                                {" "}
                                <Link to="/signin" style={{ color: 'var(--black)' }}>Sign in</Link>.{" "}
                            </p>
                        </div>

                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default Signup