import { Box, CircularProgress, Container, Grid } from '@mui/material'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { getNews } from '../../api'
import './styles.css'
import trap from '../../assets/images/trap.webp'
function Blogs() {
    const [news, setNews] = useState([]);
    useEffect(
        () => {
            getNews().then(res => {
                setNews(res.items)
            }).catch(error => console.log(error))
        }, [])
    // console.log(news.slice(0, 1))

    return (
        <>
            <div className="blogWrapper" >
                <Container style={{ padding: 0 }}>
                    <Grid container fluid >
                        <Grid item lg={6} className="blogTitle">
                            <img src={trap} width={500} height={400} />
                        </Grid>
                        <Grid item lg={6} >
                            <Box className='scrollBlogContainer'>
                                {
                                    !news ?
                                        <CircularProgress />
                                        :
                                        <div class="container">
                                            <div class="iphone-case case-1">
                                                {
                                                    news?.map((item) => (
                                                        <Box className='blogContainer'>
                                                            <img alt="blogImage" src={item.media.thumbnail.url} style={{ width: '100%', height: 150, borderRadius: "24px" }} />
                                                            <Box>
                                                                <h3>{item.title}</h3>
                                                                <Box className='footerNewsContainer'>
                                                                    <a href={item.link} >Read more</a>
                                                                </Box>
                                                            </Box>
                                                        </Box>

                                                    ))
                                                }
                                            </div>
                                        </div>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </>
    )
}

export default Blogs