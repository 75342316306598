import { Grid } from '@mui/material'
import React, { useState } from 'react'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import SigninFields from './signinFields'
import './styles.css'
import signin from '../../assets/images/login.png'
import AccountCircle from '@mui/icons-material/AccountCircle';

function Signin() {
    const [errormessage, setErrormessage] = useState('')
    return (
        <>
            <Grid container fluid >
               
                <Grid item xs={12} lg={7} className="SigninWrapper">
                    <div className='SigninContainer'>
                        <div className='SigninContainerChildOne'>
                        <AccountCircle sx={{fontSize:'100px' , color:'var(--green)' , marginBottom:"16px"}} />
                          
                            <SigninFields setErrormessage={setErrormessage} errorMessage={errormessage}/>
                            <p className='SideSigninSignup'>Don't have an account.
                                {" "}
                                <Link to="/signup" style={{ color: 'var(--white)' }}>Sign up</Link>
                            </p>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} lg={5} className="SigninWrapper">
                    <img src={signin} width='80%' height='55%'/>
                </Grid>
            </Grid>
        </>
    )
}

export default Signin