import { Box, Button, Container, Grid } from '@mui/material'
import React, { useState } from 'react'
import './styles.css'
import Cookies from 'universal-cookie'
import { useFormik } from 'formik'
import { deposite } from '../../api'
import Snackbars from '../../components/snackbar'
import deposit from '../../assets/images/deposit.svg'

function Deposit() {
    const cookie = new Cookies()
    const [successopen, setSuccessOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            wallet: '',
            amount: ''
        },
        onSubmit: () => {
            const wallet = cookie.get('wallet')
            const amount = formik.values.amount;
            const func = deposite(wallet, amount)
            func.then(async (response) => {
                if (response.success) {
                    setSuccessOpen(true)
                    window.location.reload()
                } else {
                    console.log("error");
                }
            })
        }
    })
    return (
        <>
            <Box className='depositeWrapper'>
                <Grid container>
                    <Grid item xs={12} lg={6} sx={{ display: { xs: 'none', lg: 'block', } , display:"flex" , alignItems:"center" , justifyContent:"center"}}>
                        <img src={deposit} alt='deposit'/> 
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Box className='depositeContainer'>
                            <p className='depositeTitle'>Deposit</p>
                            <form onSubmit={formik.handleSubmit} className="formContainer">
                                <Box style={{ padding: 16 }}>
                                    <p style={{ color: "var(--white)" }}>Wallet Adress:</p>
                                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Box>
                                            <input
                                                className='inputDisabled'
                                                disabled
                                                size="small"
                                                id="outlined-basic"
                                                value={cookie.get('wallet')}
                                                variant="outlined" />
                                        </Box>
                                        <Box>
                                            <Button
                                                variant="contained"
                                                onClick={() => navigator.clipboard.writeText(cookie.get('wallet'))}
                                                className="copyButton">
                                                copy to clipboard
                                            </Button>
                                        </Box>

                                    </Box>
                                </Box>
                            </form>
                        </Box>
                    </Grid>
                </Grid>

            </Box>
            <Snackbars open={successopen} text="sent Successfully!" severity="success" />
        </>
    )
}

export default Deposit