import { Box, Button, FormControl, OutlinedInput, TextField } from '@mui/material'
import React, { useState } from 'react'
import './styles.css'
import { useFormik } from 'formik';
import { register } from '../../api';
import { useNavigate } from "react-router-dom"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MuiPhoneNumber from 'material-ui-phone-number';

function SignupFields({ setErrorMessage }) {
    const [startDate, setStartDate] = useState(new Date());
    const [phoneValue, setPhoneValue] = useState()
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            wallet: '',
            username: '',
            password: '',
            birthdate: startDate,

        },
        onSubmit: () => {

            const data = {
                firstName: formik.values.firstname,
                lastName: formik.values.lastname,
                email: formik.values.email,
                username: formik.values.username,
                password: formik.values.password,
                wallet: formik.values.wallet,
                birthdate: startDate,
                phone: phoneValue,
            }

            const func = register(data)

            func.then(async (response) => {
                if (response.success) {
                    setErrorMessage('Account successfully created')
                } else {
                    setErrorMessage('User already token')
                }
            })

        }
    })
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Box className='signupinputContainer' style={{ marginBottom: 32, display: 'flex' }}>
                    <FormControl sx={{ width: '100%' }}>
                        <OutlinedInput
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.firstname || ''}
                            fullWidth
                            id="firstname-required"
                            label="First Name"
                            margin="none"
                            name="firstname"
                            placeholder='First Name'
                        />
                    </FormControl>

                    <FormControl sx={{ width: '100%' }}>
                        <OutlinedInput
                            style={{ marginLeft: 16 }}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.lastname || ''}
                            fullWidth
                            id="lastname-required"
                            label="Last Name"
                            variant="standard"
                            margin="none"
                            name="lastname"
                            placeholder='Last Name'
                        />
                    </FormControl>
                </Box>

                <Box className='signupinputContainer' style={{ marginBottom: 32, display: 'flex' }}>
                    <FormControl sx={{ width: '100%' }}>
                        <OutlinedInput
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.username || ''}
                            fullWidth
                            type="text"
                            id="username-required"
                            label="Username"
                            variant="standard"
                            margin="none"
                            name="username"
                            placeholder='Username'
                        />
                    </FormControl>
                    <FormControl sx={{ width: '100%' }}>
                        <OutlinedInput
                            style={{ marginLeft: 16 }}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.email || ''}
                            type="email"
                            fullWidth
                            id="email-required"
                            label="Email"
                            variant="standard"
                            margin="none"
                            name="email"
                            placeholder='Email'
                        />
                    </FormControl>
                </Box>

                <Box className='phoneContainer' style={{ marginBottom: 32, display: 'flex' }}>
                    <div style={{ width: '100%' }}>
                        <MuiPhoneNumber
                            style={{width: '100%' }}
                            defaultCountry={'us'}
                            onChange={setPhoneValue}
                            value={phoneValue}
                            name="phone"
                            placeholder='Phone Number'
                            className='phoneNumber'
                        />
                    </div>
                    <div style={{ marginLeft: 16, width: '100%' }}>
                        <DatePicker
                            className='date'
                            selected={startDate}
                            name='birthdate'
                            onChange={(date) => setStartDate(date)}
                            value={startDate} 
                            />
                    </div>
                </Box>

                <Box className='signupinputContainer' style={{ marginBottom: 32, display: 'flex' }} >
                    <FormControl sx={{ width: '100%' }}>
                        <OutlinedInput
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.password || ''}
                            type="password"
                            fullWidth
                            id="password-required"
                            label="Password"
                            // variant="standard"
                            margin="none"
                            name="password"
                            placeholder='Password'
                        />
                        </FormControl>
                </Box>

                <Box style={{ marginTop: 45 }}>
                    <Button
                        type="submit"
                        variant="contained"
                        className='signupsubmitButton'
                    >
                        Sign Up
                    </Button>
                </Box>

            </form>
        </>
    )
}

export default SignupFields